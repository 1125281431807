input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
    font-size: 100%;
}

input[type=search].search-val {
    max-width: 200px;
    border: 1px solid #ced4da;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(109, 207, 246, .5);
}

input[type=search].search-empty {
    width: 0%;
}

input[type=search].search-empty:hover {
    cursor: pointer;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
    display: none;
}

input[type=search] {
    background: transparent url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
    padding: 1px 5px 0px 30px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

input[type=search]:focus {
    width: 200px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
    -moz-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
    box-shadow: 0 0 5px rgba(109, 207, 246, .5);
}

input:-moz-placeholder {
    color: #999;
}

input::-webkit-input-placeholder {
    color: #999;
}

@media(max-width: 768px) {
    input[type=search].search-empty,
    input[type=search]:focus{
        width: 86%;
        margin-right: 0;
    }
    input[type=search].search-val {
        max-width: 89%;
    }
}