.my-lists {
  list-style: none;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  margin-bottom: 0;
  width: 100%;
  padding-left: 0;
}

.btn-input-file-download {
  background-color: transparent !important;
  border: none;
}

.list-view {
  padding: 10px;
  border-radius: 0.25rem;
  border: 1px solid var(--co-border-color);
  margin-bottom: 1rem;
  width: 100%;
  float: left;
}

.list-view-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.file-info {
  width: 25%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.list-view .file-name {
  color: #404040;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

.completed-file-status,
.queued-file-status,
.running-file-status,
.failed-file-status {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.queued-file-status,
.running-file-status {
  color: #ff9e2f !important;
}

.failed-file-status {
  color: #ce1c1c;
}

.completed-file-status {
  color: #397b39 !important;
}

.delivery_status {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  padding: 0 0 0 5px;
}

.no_risk {
  color: green;
}

.delivery_status.moderate_risk {
  color: #ffa500;
}

.delivery_status.high_risk {
  color: #ff0000;
}

/* ---- result table css -----*/
.analysis-billable-table {
  width: 50%;
  display: flex;
  margin-right: 4px;
  align-items: flex-start;
}

.analysis-result-container {
  display: flex;
  flex-direction: column;
  width: 55%;
  border: 1px solid #b7b7b745;
  border-radius: 3px;
}

.analysis-result-container>div:first-child {
  text-align: center;
  font-size: 16px;
  padding: 4px 0;
  letter-spacing: 0.3px;
  background-color: var(--co-badge-color);
}

.analysis-info-table {
  margin-bottom: 0;
  background-color: #fff;
}

.analysis-info-table table {
  margin-bottom: 0;
}

.analysis-info-table td {
  font-size: 15px;
  color: #777;
  padding: 4px 5px 7px 5px !important;
  vertical-align: top;
  text-align: left;
}

.analysis-billable-table>table {
  width: 40%;
}

.analysis-info-table td:first-child {
  font-size: 15px;
}

.analysis-info-table td:nth-child(2) {
  color: #505050;
  text-align: right;
  padding-right: 10px;
}

.analysis-info-table .valid-info-table {
  width: 48%;
  border-right: 1px dashed hsla(0, 0%, 71.8%, 0.27) !important;
}

.analysis-info-table .analysis-tab-info {
  width: 52%;
}

.deliverability-results-info-table {
  margin-bottom: 5px;
}

.deliverability-results-info-table td {
  padding: 2px 0;
  vertical-align: middle;
}

.deliverability-value {
  margin: 0;
  line-height: normal;
  font-weight: 600;
  font-size: 14px;
  word-break: break-all;
  text-align: right;
}

.deliverability-value.High {
  color: #080;
}

.deliverability-value.Moderate {
  color: #00f;
}

.deliverability-value.Low {
  color: #ff7f50;
}

.deliverability-value.Very-Low {
  color: #f00;
}

.cop-listdetails-flag-svg svg {
  display: block;
  height: 20px;
}

.result-value {
  margin: 0;
  line-height: normal;
  font-weight: 600;
  font-size: 15px;
  word-break: break-all;
}

/* ---- result table css ends-----*/

.list-view-container .file-upload-progress {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.list-view-container .file-upload-progress .CircularProgressbar {
  height: 140px;
}

.chart-data-info {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-self: center;
}

.file-uploaded-date {
  color: #777;
  margin-top: 2px;
  line-height: normal;
  margin-bottom: 2px;
  line-height: normal;
  font-size: 14px;
}

.progress-info-div {
  width: 30%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  align-self: center;
}

.list-uploaded-file-img {
  height: 25px;
  width: 25px;
  fill: currentColor;
  margin-right: 5px;
  vertical-align: sub;
  display: inline-block;
}

.list-type-filter {
  width: 140px;
}

.list-type-filter .css-15k3avv {
  z-index: 2 !important;
}

.credits-req-span {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
}

.lists-filters {
  padding: 0;
  width: 80%;
}

.filter-menu-item>div {
  font-size: 15px;
}

.filters-div {
  gap: 0.5rem;
}

.filters-div .btn-clearout-refresh {
  padding: 10px !important;
}

.download-result-modal .modal-footer {
  background-color: #fff;
}

.download-result-modal .modal-body {
  padding-top: 10px;
  padding-bottom: 0px;
}

.download-option-desc.selected-option {
  display: block;
  padding: 10px 10px;
}

.download-option-desc .append-checkbox-values {
  padding-top: 0.5rem;
}

.download-option-desc {
  display: none;
}

.append-checkbox {
  font-size: 15px;
  line-height: normal;
  padding: 8px 10px 8px 40px;
  border-bottom: 1px solid #f4f5f7;
  border-right: 1px solid #f4f5f7;
  margin-right: 0.5rem;
}

.append-checkbox .custom-control-label {
  padding-top: 11px;
  padding-bottom: 10px;
}

.append-checkbox .custom-control-label::before,
.append-checkbox .custom-control-label::after {
  margin-top: 10px;
  margin-bottom: 10px;
}

.append-checkbox-div {
  padding-right: 0;
  padding-left: 0;
}

.append-checkbox-div .radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.download-option-desc .option-desc {
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
  color: #636363;
}

.download-option-desc .option-heading {
  margin-bottom: 8px;
  color: #868686;
  font-size: 23px;
  margin-top: 0px;
}

.download-option-div {
  background-color: whitesmoke;
  padding-left: 0;
}

.filter-checkbox-values {
  font-size: 14px;
  padding: 5px 5px 5px 25px;
}

.filter-checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.filter-val {
  font-weight: 600;
  line-height: normal;
  align-self: center;
  margin-bottom: 2px;
}

.append-checkbox .custom-control-label {
  width: 100%;
}

div.append-checkbox-div .custom-control-label:hover {
  cursor: pointer;
}

#all_result+.custom-control-label {
  padding-bottom: 0;
}

.all-result-text {
  font-size: 13px;
  padding-left: 22px;
}

.append-checkbox-values {
  font-size: 14px;
  display: flex;
}

.export-list-modal .modal-body {
  padding-top: 10px;
}

.export-list-modal {
  width: 600px;
  max-width: 650px;
}

.close-icon {
  text-align: center;
  font-size: 14px;
  line-height: normal;
  display: inline-block;
  margin-left: 10px;
}

.close-icon:hover {
  cursor: pointer;
}

.close-icon:before {
  content: "x";
  font-weight: bold;
  color: #6d6969;
  line-height: normal;
  vertical-align: top;
}

.safe-to-send-tr {
  font-size: 13px;
  font-weight: 600;
  background-color: #e5ffe5;
}

.safe-to-send-tr td {
  text-align: center;
  vertical-align: middle;
  padding-bottom: 5px;
  color: #505050;
}

.show-more-settings-metric {
  font-size: 14px;
}

.additional-metrics {
  background-color: #e8e8e861 !important;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  margin: 3px 0 5px 0;
}

.deliverability-value {
  margin: 0;
  line-height: normal;
  font-weight: 600;
  font-size: 14px;
  word-break: break-all;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  color: #605f5f;
}

.email-search {
  border: 1px solid var(--co-border-color);
}


@media (max-width: 768px) {

  .file-info,
  .analysis-result-container,
  .file-info>div {
    width: 100%;
  }

  .progress-info-div>button,
  .progress-info-div,
  .chart-data-info {
    width: 100%;
    margin-top: 10px;
  }

  .deliverability-results-info-table {
    max-width: 100%;
  }

  .lists-filters>div:first-child,
  .filters-div,
  .list-view-container {
    flex-direction: column;
  }

  .lists-filters {
    width: 100%;
    margin-right: 0;
  }

  .filters-div .btn-clearout-refresh {
    width: 100%;
    margin-top: 5px;
  }

  .analysis-result-container {
    margin-top: 5px;
  }

  .download-result-modal,
  .export-list-modal {
    width: auto;
  }

  .email-search {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .lists-filters.subs-lists-filters {
    width: 100%;
    flex-direction: column;
  }

  .users-list-org-select,
  .search-input-grp,
  .organization-filter-select {
    width: 100% !important;
    margin-bottom: 3px;
  }
}

.email-search {
  width: 20%;
  height: 38px;
}

.file-expire-date {
  text-align: center;
  font-weight: 600;
  color: #404040;
  font-size: 12px;
}

.custom-control-label {
  padding-bottom: 0 !important;
}

.download-ef-info-div {
  width: 30%;
  justify-content: center;
  text-align: center;
  /* margin-top: 10px; */
  align-self: center;
}

.label-success {
  background-color: #00a65a !important;
  color: white;
  font-size: 11px;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.btn-clearout-export {
  min-width: 188px;
}