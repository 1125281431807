.text-orange {
    color: orange;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

@media (max-width: 768px) {
    .analytics-chart {
        width: 100%;
    }
}