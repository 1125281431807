.fs-7 {
  font-size: 0.875rem !important;
}

.crm-show-more-toggle-btn {
  background-color: transparent !important;
  color: var(--co-primary) !important;
  outline: none !important;
  box-shadow: none !important;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

/* Extra small devices (xs) */
@media (min-width: 0) and (max-width: 575.98px) {
  .fs-xs-7 {
    font-size: 0.875rem !important;
  }
}

/* Small devices (sm) (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .fs-sm-7 {
    font-size: 0.875rem !important;
  }
}

/* Medium devices (md) (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .fs-md-7 {
    font-size: 0.875rem !important;
  }
}

/* Large devices (lg) (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .fs-lg-7 {
    font-size: 0.875rem !important;
  }
}

/* X-Large devices (xl) (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .fs-xl-7 {
    font-size: 0.875rem !important;
  }
}

/* XX-Large devices (xxl) (larger desktops, 1400px and up) */
@media (min-width: 1400) {
  .fs-xxl-7 {
    font-size: 0.875rem !important;
  }
}

