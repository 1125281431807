.action-button {
  width: clamp(200px, 60%, 400px);
  max-width: 100%;
}

.dropdown-item {
  padding: 0 3px !important;
}

.sm-btns {
  height: 24px;
  width: 26px;
  padding: 2px;
}

.owner-badge {
  background-color: #FF5349 !important;
}

.basic-info-section {
  background-color: #e8e8e880;
}

.credits-limits-section {
  background-color: #e8e8e880;
}

.action-dropdown {
  padding: 0rem 0.5rem;
  width: clamp(200px, 60%, 400px);
  max-width: 100%;
}

.info-section-container {
  border: 2px solid #e8e8e880;
  border-radius: 10px;
}

.receipt-table .mulitline-header {
  display: flex;
  flex-direction: column;
  min-width: 4rem;
  width: fit-content;
  font-size: 15px;
}

.mulitline-header.description {
  min-width: 12rem;
  display: flex;
  flex-direction: column;
}

.receipt-table tbody {
  font-size: 15px;
}

.invoice-recept-table {
  overflow-x: auto;
}

.plan-tab-headers {
  display: flex;
  place-content: flex-end;
  gap: 0.25rem;
}

.custom-product-name-dropdown label {
  font-size: small;
  position: relative;
  color: #4c4c4c;
  top: 10px;
  left: 6px;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
}

.product-card {
  padding-top: 1.5rem;
}

.product-card.plan {
  height: 500px;
  overflow: hidden scroll;
}

.product-card.addon {
  height: 280px;
}

.plan-tab__body {
  margin-top: 1rem;
}

.plan-filters {
  display: flex;
  gap: 0.25rem;
}

.plan-subs-list-table {
  height: 650px;
}

.payment-settings-toggle {
  display: flex;
  align-items: center;
}

.payment-toggle-label {
  flex-basis: 50%;
  text-align: right;
}

.acap-toggle .react-toggle-track,
.alap-toggle .react-toggle-track {
  height: 14px;
  width: 40px;
}

.acap-toggle .react-toggle-thumb,
.alap-toggle .react-toggle-thumb {
  height: 14px;
  width: 14px;
  top: 0px;
}

.coupon-dropdown {
  padding: 0rem 0.5rem;
  width: 10px;
  max-width: 100%;
}

.payment-settings-reason {
  flex-basis: 30%;
  text-align: right;
}

@media (max-width: 768px) {
  .action-button {
    width: 100%;
  }

  .action-dropdown {
    width: 100%;
  }

  .extra-info-section {
    text-align: center;
  }

  .remaining-credits {
    justify-content: center;
    align-items: center;
  }

  .user-info {
    flex-direction: column;
    gap: 0.25rem;
  }

  /* .receipt-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    } */

  .plan-tab-headers {
    flex-direction: column;
  }

  .custom-product-name-dropdown label {
    top: 7px;
  }

  .plan-filters {
    flex-direction: column;
  }
}


/* new css */

.card {
  max-width: 100% !important;
}

.custom-card {
  background-color: #f7f7f76b !important;
}

.custom-card:hover {
  border-color: rgba(0, 0, 0, 0.175) !important;
}

.forgot-password-btn {
  font-size: 16px;
}

.invoice-recept-tab {
  position: sticky;
  padding: 10px;
}

.quick-links-icons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.quick-links-icons img {
  width: 18px;
  height: 18px;
}

@media (max-width: 1900px) {
  .forgot-password-btn {
    font-size: 13px;
    padding: 4px;
  }
}

.image-card {
  top: 10px, ;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border: 2px solid white;
  z-index: 10;
  padding: 3px;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

