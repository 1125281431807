.prospect-search-input {
  width: 70%;
  align-self: center;
  padding: 0 1rem;
}

.requests-container {
  gap: 1rem;
  padding: 1rem;
  font-size: 16px;
  border-radius: 0.3rem;
  width: 70%;
  flex-grow: 1;
  align-self: center;
}

.no-requests-found {
  align-items: center;
  color: gainsboro;
  height: 70vh;
}

.prospect-search {
  flex-grow: 1;
}

.request-card {
  background: #f7f7f76b !important;
  padding: 1rem;
  border-radius: 0.5rem;
}

.enrich-lead {
  padding: 6px 12px 6px 0;
}

.profile-name {
  flex: 0 1 70%;
}

.enrich-lead-list {
  padding: 6px 12px 6px 0;
}

.download-as-csv {
  padding: 6px 12px 6px 0;
}

.file-uploaded-date {
  color: #777;
  line-height: normal;
  margin-top: 2px;
  margin-bottom: 0px;
  line-height: normal;
  letter-spacing: 0.3px;
  font-size: 14px;
}