.create-product {
  display: flex;
  gap: 1rem;
}

.product-custom-select .css-1nmdiq5-menu {
  z-index: 10;
}

.product-create-form,
.product-view {
  flex-basis: 50%;
  border: 1px solid #e8e8e8;
  border-radius: 0.25rem;
  padding: 1rem;
  justify-content: center;
}

.product-custom-input {
  display: flex;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 0.375rem;
}

.product-custom-input .form-control {
  border: none;
}

.product-input-clear-btn {
  background-color: transparent !important;
  color: #cccccc !important;
  font-weight: bold;
  font-size: 1em;
  border: 0;
}

.product-is-custom-check,
.product-is-recurring-check {
  display: flex;
  gap: 0.5rem;
}

.product-create-form label {
  font-size: small;
  position: relative;
  color: #4c4c4c;
  top: 10px;
  left: 6px;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
}

.product-intervals,
.product-intervals {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.interval-unit,
.interval-count,
.interval-times {
  flex: 1 0 30%;
}

.product-amount-input,
.product-credits-input,
.currency-dropdown {
  flex: 1 0 30%;
}

.plan-amount-n-credits,
.addon-amount-n-currency {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.plan-custom-n-recurring,
.addon-custom-n-recurring {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.view-product-btn {
  float: right;
  margin-top: 0.25rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.create-product-btn {
  min-width: min(100%, 300px);
  place-self: center;
}

.product-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
}

.generate-url{
  font-size: 16px;
  font-weight: 600;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  max-width: 90%;
  overflow: hidden;
}

@media (max-width: 768px) {
  .create-product {
    flex-direction: column;
    translate: flex-direction 0.5s ease-in-out;
  }

  .product-create-form label {
    top: 7px
  }
}