.product-filters {
  display: flex;
  margin-bottom: 0.5rem;
  flex-direction: row;
}

.product-filters .filter-comp {
  border-radius: 0.25rem 0 0 0.25rem;
  transition: width 0.5s ease-in-out;
}

.product-filters .filter-btn {
  border-radius: 0 0.25rem 0.25rem 0;
  transition: width 0.5s ease-in-out;
}

.product-filters .search-container {
  margin-left: 0.5rem;
  transition: width 0.5s ease-in-out;
  min-width: 300px;
}

.product-list-table {
  height: calc(100svh - 14rem);
}

@media (max-width: 768px) {
  .product-filters {
    flex-direction: column;
    padding: 0 0.25rem;
    gap: 0.25rem;
  }

  .product-filters .filter-btn,
  .product-filters .filter-comp,
  .product-filters .search-container {
    width: 100% !important;
    border-radius: 0.25rem;
    margin: 0;
  }

  .product-list-table {
    height: calc(100dvh - 17.5rem);
  }
}