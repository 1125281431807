.list-selected-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  margin-left: 0.25rem;
}

.filter-comp {
  border: 1px solid var(--co-border-color);
  border-radius: 4px;
  display: flex;
  width: 100%;
}

.user-list-filter-comp {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.filters-div {
  display: flex;
  margin-top: 15px;
  margin-bottom: 0 !important;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.filter-badge {
  border: 1px solid #e8e8e8;
  color: #4a4a4a !important;
  font-weight: normal;
  font-size: 14px;
  padding: 6px 8px;
  background-color: #e8e8e8 !important;
  line-height: normal;
  border-radius: 2px;
}

.filter-refresh-btn {
  border: 1px solid var(--co-border-color);
}

.filter-reset-btn {
  background-color: transparent;
  border: 1px solid lightgray;
  color: black;
  font-size: 15px;
  padding: 4px 12px;
  transition: background-color 0.3s, color 0.3s;
}

.filter-reset-btn:hover {
  background-color: grey;
  color: white;
}

.filter-reset-btn:focus,
.filter-reset-btn:active {
  box-shadow: none;
  background-color: grey !important;
  color: white !important;
}

.close-icon {
  text-align: center;
  font-size: 14px;
  line-height: normal;
  display: inline-block;
  margin-left: 10px;
}

.close-icon:hover {
  cursor: pointer;
}

.close-icon:before {
  content: 'x';
  font-weight: bold;
  color: #6d6969;
  line-height: normal;
  vertical-align: top;
}

.action-data {
  width: 900px;
}

.user-info-header {
  padding-left: 250px;
}

.org-filter {
  width: 250px;
}

.search-filter {
  width: 200px;
}

.filter-container {
  display: flex;
  gap: 0.5rem;
  padding-bottom: 0.25rem;
}

.search-container {
  position: relative;
  width: 30%;
}

.clear-button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  border: none;
  cursor: pointer;
  color: #999;
}

.day-filter {
  width: 500px;
}

.cursor-pointer {
  cursor: pointer;
}

.filter-btn {
  border-radius: 4px;
}

.buttons---btn-group---1jaDo>.buttons---btn---2jZHN:first-child {
  height: 35px;
  font-size: 14px;
  border: none;
}

.analytics-filter {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0px;
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
  }

  .search-container {
    width: 100%;
    margin-bottom: 5px;
  }

  .day-filter {
    width: 100%;
    margin: 7px 0px !important;
  }

  .org-filter {
    width: 100%;
    margin: 7px 0px !important;
  }

  .analytics-filter {
    flex-direction: column;
  }

  .filter-btn {
    width: 100%;
  }

  .filter-badge {
    font-size: 12px;
    padding: 2px 8px;
  }
}