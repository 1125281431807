#root {
  height: 100dvh;
}

body {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
}

#dashboard {
  height: 100%;
  display: flex;

  --crm-sidebar-width: 240px;
  --crm-sidebar-collapsed-width: 70px;
}

#header-main-content-container {
  width: calc(100% - var(--crm-sidebar-width));
  transition: transform 0s ease, width 0.7s ease;
  display: flex;
  flex-direction: column;
}

#header-main-content-container.expand {
  width: 100%;
  z-index: 999;
}

#header-main-content-container.sidebar-collapsed {
  width: calc(100% - var(--crm-sidebar-collapsed-width));
}

#header {
  >nav {
    box-shadow: var(--co-header-box-shadow);
    height: 60px;
    padding: 5px !important;
  }

  .logo {
    height: 34px;
    transition: width 0.3s ease-in-out;
    width: 200px;
  }

  .header-search {
    width: max(30vw, 255px);
    background-color: #f1f1f182 !important;
    border: none;
    height: 40px;
    padding-left: 35px;
    padding-bottom: 10px;
  }

  .logo.mini {
    width: 40px;
  }

  #header-dropdown {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
  }

  #header-dropdown::after {
    display: none;
  }
}

#sidebar.hidden {
  transform: translateX(-100%);
  width: 0%;
}

#sidebar.visible {
  width: 60%;
  transition: transform 0s ease, width 0.5s ease;
  transform: translateX(0);
  position: absolute;
  top: 0;
  z-index: 1000;
}

#sidebar.collapsed {
  transition: transform 0s ease, width 0.5s ease;
  width: var(--crm-sidebar-collapsed-width);
  align-items: center;
}

/* Sidebar overlay */
#sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
}

/* Show overlay */
#sidebar-overlay.visible {
  display: block;
}

#sidebar {
  display: inherit;
  flex-direction: column;
  flex: 1 0 auto;
  width: var(--crm-sidebar-width);
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  transition: transform 0s ease, width 0.5s ease;
  border-right: 1px solid var(--co-border-color);
  background-color: var(--co-body-bg);

  .accordion-item {
    border: none;
  }

  .accordion-body {
    padding: 0 !important;
  }

  .accordion-button:focus {
    box-shadow: none !important;
  }

  .accordion-header button {
    display: flex;
    letter-spacing: 0.3px;
    border-radius: 5px;
    color: #353a44;
    font-weight: 600;
    box-shadow: none !important;
    font-size: 15px;
    padding: 0.6rem 0.5rem 0.6rem 1rem ;
  }

  .nav-link {
    color: #353a44;
    font-weight: 500;
    font-size: 15px;
  }

  .sidebar-close-icon {
    color: #353a44;
  }

  .nav-link.active {
    border-radius: 5px;
    font-weight: 600 !important;
    color: var(--co-primary);
  }

  .nav-link:hover {
    background-color: var(--co-badge-color);
    border-radius: 5px;
  }

  .accordion-header {
    --bs-accordion-active-bg: none !important;
  }

  .accordion-body a {
    display: flex;
    column-gap: 0.5rem !important;
    align-items: center;
    margin: 0rem 0.5rem 0rem 2rem;
    padding: 3px 8px 3px 8px;
    text-decoration: none;
  }

  .close {
    width: 60px;
    flex: 0 1;

    .accordion-button::after,
    .accordion-collapse,
    .accordion-header button span {
      display: none;
    }

    .accordion-button img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

#sidebar.close {
  display: inherit;
  max-width: 60px;
  flex: 1 0 35%;
}

#mainContent {
  display: inherit;
  height: auto;
  padding: 1rem 1rem 0 1rem;
  overflow-y: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
  flex-direction: column;
  flex-grow: 1;
}

#page-header {
  margin-bottom: 0.5rem;
}

#page-content {
  width: 100%;
  overflow-y: auto;
  height: auto;
  flex: 1;
}

.header {
  font-size: 24px !important;
  --bs-heading-color: var(--co-heading-color);
  font-weight: 600;
}

.bg-success,
.bg-secondary,
.bg-danger {
  border-radius: 8px;
  font-size: 11px;
}

.sidebar-popover {
  .popover-header, .popover-body {
    padding: 0;
  }

  .popover-arrow {
    display: none;
  }
}

/* Styles for the client initials badge */
.client-initials-container {
  min-height: 80px;
  display: flex;
  align-items: center;
}

.client-initials {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--co-client-initial);
  color: white;
  border-radius: 10%;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  .form-control {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) {
  p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) {
  #mainContent {
    padding: 1rem 0.25rem !important;
    overflow-y: scroll;
  }
}