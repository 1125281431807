.cards {
  column-gap: 20px;
}

.clickable_item {
  color: #394c67;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;

}

.clickable_item:hover {
  background-color: #eee;
  transition: background-color 0.3s ease;
  border-radius: 0.4rem;
}

.card {
  border-radius: 0.625rem !important;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: 20px;
  position: relative;
  width: 100% !important;
}

/* .card-tag {
  border: 1px solid rgba(var(--co-primary-rgb), .6) !important;
} */

.plan-card {
  min-height: 556px;
}

.card:hover {
  box-shadow: 4px 4px 20px rgba(var(--co-primary-rgb), .4);
  border-color: rgba(var(--co-primary-rgb), 0.2);
}

.card-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.75rem 0rem;
  font-size: 28px;
  font-weight: 600;
  min-height: 200px;
  border-bottom: none !important;
}

.header-title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
}

.price {
  font-size: 18px;
  color: #394c67;
}

.header-subtitle {
  color: rgb(141, 138, 138);
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
}

.plan-name {
  width: 100%;
  display: flex;
  font-size: 19px !important;
  font-weight: 600;
  color: #4c4c4c !important;
  font-family: var(--theme-font-family);
  justify-content: space-between;
}

.pricing-text {
  font-weight: 600;
  font-size: 24px;
  color: rgba(var(--co-primary-rgb));
}

.list-group-item {
  color: #394c67;
  border: none !important;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  height: 3.5rem;
  align-items: baseline;
}

.list-group-item svg {
  color: rgba(var(--co-primary-rgb));
  margin-right: 12px;
}

.list-group-item:last-child {
  border-bottom: none !important;
}

.has_tooltip {
  text-decoration: rgb(82, 82, 82) underline dashed from-font;
}

.item-details__tooltip {
  max-width: 280px;
  opacity: 0.7 !important;
}

.card-tag__best-value {
  background: var(--co-primary) !important;
  border-radius: 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 16px;
  position: absolute;
  right: 25px;
  top: -15px;
  z-index: 1;
}

.plan-select-container {
  width: 100%;
}

.plan-select-container .plan-select__single-value {
  height: 2rem;
  line-height: 2rem;
  padding: 0;
}

.plan-select-container .plan-select__control {
  border: 1px solid rgb(204, 204, 204) !important;
  box-shadow: none;
  outline: none;
  height: 2rem;
  line-height: 2rem;
}

.plan-select-container .plan-select__control:hover {
  border: 1px solid rgb(204, 204, 204) !important;
}

.plan-select-container .plan-select__option {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  align-items: center;
  background-color: #fff;
  color: #4c4c4c;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.plan-select-container .plan-select__menu-list {
  padding: 8px;
}

.plan-select-container .plan-select__option:last-child {
  border-bottom: none;
}

.plan-select-container .plan-select__option:hover {
  background-color: #f6f6f6;
  color: #4c4c4c;
}

.best-value__badge {
  background: rgba(var(--co-primary-rgb));
  border-radius: 0.125rem;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 4px;
  position: relative;
  z-index: 1;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: .25;
  vertical-align: middle;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(141, 138, 138)
}

.plan-dropdown_options {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  color: rgb(141, 138, 138)
}

.strike-price {
  text-decoration: line-through;
  font-size: 20px;
  color: #c5c5c5
}

.dropdown-strike-price {
  text-decoration: line-through;
  font-size: 16px;
  color: #c5c5c5
}

.normal-price {
  color: #394c67;
  font-size: 28px;

}

.dropdown-normal-price {
  font-size: 20px;
  color: #394c67;

}

.month {
  font-size: 14px;
}




















/* addon card */

.subscription-addons-container {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.addon-title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.addon-subtitle {
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.addons-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.addon-card {
  border: 2px solid #ddd;
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  max-width: 550px;
  width: 100% !important;
  transition: border-color 0.3s ease-in-out;
}

.addon-card:hover {
  box-shadow: 4px 4px 20px rgba(var(--co-primary-rgb), 0.4);
  border-color: rgba(var(--co-primary-rgb), 0.2) !important;
}

/* .addon-card:hover,
.pay-as-you-go-addon-card:hover {
  box-shadow:  4px 4px 20px rgba(var(--theme-color),.4)
} */
.card-body {
  padding: 0px;
}

.addon-header {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}



.addon-dropdown {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  gap: 10px;
}

.dropdown-label {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  text-align: right;
}

.addon-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.addon-details {
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-between;
  align-items: flex-start;
  flex-basis: 60%;
}

.price-details {
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-between;
  align-items: flex-end;
  flex-basis: 40%;
}


.button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}

.react-select-container__addon {
  margin-right: 10px;
  /* Add some margin for better separation */
}

.addon_button {
  width: 145px;
}

.current-addon_btn {
  width: 180px;
}

.quantity {
  font-size: 16px;
  font-weight: 501;
}

.addon-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  color: #999;
}

.addon-description span {
  margin-bottom: 20px;

}

.note {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  color: #999;
}

.quantity-selector {
  text-align: right;
  flex-basis: 50%;
  height: fit-content;
}

.quantity-selector .quantity__control {
  text-align: center;
  border: 1px solid #ccc !important;
  flex-wrap: nowrap;
  min-height: 24px;
}

.quantity-selector .quantity__value-container,
.quantity-selector .quantity__indicator {
  padding: 0;
}

.quantity-selector .quantity__value-container,
.quantity-selector .quantity__indicator {
  padding: 0;
}

.quantity-selector .quantity__indicator-separator {
  display: none;
}

.quantity-selector .css-1pahdxg-control,
.quantity-selector .css-1pahdxg-control:hover {
  box-shadow: none;
  border: 1px solid rgb(204, 204, 204) !important;
}

.quantity-selector .quantity__option {
  background-color: #fff;
  color: #000;
}

.quantity-selector .quantity__option:hover {
  background-color: #f6f6f6;
  color: #000;
}

.currency-select .quantity__option:hover {
  background-color: #f6f6f6;
  color: #000;
}

.currency-select .quantity__option--is-selected {
  background-color: #f6f6f6;
  color: #000;
}

.quantity-selector .quantity__indicator>svg {
  height: 16px;
  width: 16px;
  fill: rgba(255, 92, 53, .6);
  cursor: pointer;
}

.price-value {
  font-size: 20px;
  font-weight: 600;
  color: #394c67;
}

.quantity-selector .quantity__option {
  font-size: 16px;
  word-wrap: normal;
  padding: 0;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .addons-container {
    flex-direction: column;
    align-items: center;
  }

  .addon-card {
    flex-direction: column;

  }

  .addon-content {
    flex-direction: column;
  }

  .addon-details {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    align-self: center;
  }

  .price-details {
    margin-top: 20px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    align-self: center;
  }

  .note {
    margin: 0px;
  }

  .addon-dropdown {
    align-items: center;
  }

  .addon-description {
    text-align: center;
  }

  .price-value {
    font-size: 18px;
  }

  .button {
    width: 100%;
  }
}