.credits-format {
  width: 550px;
}


.created_on {
  width: 250px;
}

.name {
  width: 250px;
}

.last_purchased {
  width: 250px;
}

.recur {
  width: 200px;
}

.email-cell {
  width: 300px;
}

.phone-cell {
  width: 200px;
}

.credits {
  width: 250px;
}

.subscription {
  width: 300px;
  font-size: 14px;
  white-space: break-spaces;
}

.activated_on {
  width: 250px;
}

.created-on-cell {
  width: 220px;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-data-download {
  padding: 4px 7px 5px 7px;
  margin-right: 0.5rem;
}

.user-data-download svg {
  width: 18px;
}

.user-data-download:disabled:hover {
  cursor: not-allowed;
}

.sticky-cell {
  white-space: nowrap;
}

.sticky-on-desktop {
  position: sticky;
  left: 0;
  z-index: 0;
}

th {
  z-index: 0 !important;
  font-weight: 501;
  border-color: var(--co-badge-color) !important;
}

.PhoneInput {
  padding: 4px 1px;
}

.PhoneInputCountryIcon {
  font-size: 25px
}

.PhoneInputInput {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: border-color 0.2s ease-in-out;
}

.PhoneInputInput:focus {
  border-color: indigo;
}

.channel_source {
  max-width: 200px;
  font-weight: 400;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  cursor: pointer;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  line-height: normal;
  margin-left: 3px;
  letter-spacing: .5px;
}

.channel_source:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}

.user-list-table-container .table-responsive {
  height: calc(100vh - 220px);
}

.user-list-table-container tbody,
.user-list-table-container td,
.user-list-table-container tfoot,
.user-list-table-container th,
.user-list-table-container thead,
.user-list-table-container tr {
  border-style: dotted;
}

.user-list-name-cell-container {
  min-width: 250px;
}

.user-list-name-cell-container .co-name-cell-link {
  color: #727478;
  cursor: pointer;
}

.user-list-table-row {
  height: 80px;
}

.user-list-table-row:hover .user-list-table-icon-wrapper {
  display: flex;
  font-weight: bold;
}

.user-list-table-row {
  transition: font-weight 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  opacity: 0.8;
}

.user-list-table-row:hover {
  font-weight: 600;
  opacity: 1;
}

.user-list-table-row:hover .co-name-format {
  font-weight: bold !important;
}

.user-list-table-icon-wrapper {
  display: none;
}

.co-email-copy {
  width: 25px;
}

.co-email-copy svg {
  color: #727478;
}

.co-name-format {
  color: #4e4f52;
  font-weight: 600;
}

/* Media query to remove sticky positioning on smaller screens */
@media (max-width: 768px) {
  .sticky-on-desktop {
    position: static;
  }
}

.user-activities-text {
  color: rgba(33, 37, 41, 0.75);
}

.input-text {
  font-weight: 600;
}