.login-container {
  background: linear-gradient(135deg, #e8f0fe, #ffffff);
}

.login-card {
  max-width: 400px;
  width: 100%;
}

.login-logo {
  max-width: 150px;
}

.sign-in {
  background-color: #ff9e30;
}

.sign-in:hover {
  background-color: #eb691e99;
}